<template>
  <el-card class="box-card">
    <div class="title">
      <p>解锁账号</p>
      <div class="line"></div>
    </div>
    <el-form :model="regFrom" :rules="rules" ref="ruleForm">
      <div class="posinput">
        <el-form-item prop="useremail" class="inputcontent">
          <el-input v-model="regFrom.useremail" placeholder="请输入邮箱"></el-input>
          <img class="emailimg" :src="require('@/assets/imgs/youxiang.png')" />
        </el-form-item>
        <div class="slider"><SliderLogin @loginver="forgetMsg" /></div>
        <el-form-item prop="verificationval" class="inputcontent veriCodes">
          <el-input
            v-model="regFrom.verificationval"
            placeholder="请输入验证码"
          ></el-input>
          <img :src="require('@/assets/imgs/yanzhengma.png')" />
          <div class="sendmsg" @click="sendmsg">
            <p v-show="msgtime">发送验证码</p>
            <p v-show="!msgtime">{{ count }}S</p>
          </div>
        </el-form-item>
        <el-form-item prop="password" class="inputcontent">
          <img :src="require('@/assets/imgs/mima.png')" />
          <el-input
            v-model="regFrom.password"
            placeholder="请输入密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="secondpassword" class="inputcontent">
          <img :src="require('@/assets/imgs/mima.png')" />
          <el-input
            v-model="regFrom.secondpassword"
            placeholder="请确认密码"
            type="password"
          ></el-input>
        </el-form-item>
      </div>
      <div class="determine">
        <button @click="changelang('ruleForm')"><p>确 定</p></button>
      </div>
    </el-form>
  </el-card>
</template>

<script>
import SliderLogin from "@/components/slier/SliderLogin";
import * as unlock from "@/api/apis";
export default {
  data() {
    var emails = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      if (value == "" || value == undefined || value == null) {
        callback(new Error("请输入邮箱"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请输入正确的邮箱"));
        } else {
          callback();
        }
      }
    };
    var checkMaxVal = (rule, value, callback) => {
      if (value == "" || value == undefined || value == null) {
        callback(new Error("请输入密码"));
      } else if (value < 18 && value > 6) {
        callback(new Error("请输入6-16位数密码"));
      } else {
        callback();
      }
    };
    var conPasVal = (rule, value, callback) => {
      if (value == "" || value == undefined || value == null) {
        callback(new Error("请输入确认密码"));
      } else if (this.regFrom.password === value) {
        callback();
      } else {
        callback(new Error("两次密码不一样请重新输入"));
      }
    };
    return {
      msgtime: true,
      count: "",
      timer: null,
      sliderVal: false /* 滑动验证 */,
      rules: {
        useremail: [
          {
            required: true,
            validator: emails,
            trigger: "blur",
          },
        ],
        verificationval: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: checkMaxVal,
            trigger: "blur",
          },
        ],
        secondpassword: [
          {
            required: true,
            validator: conPasVal,
            trigger: "blur",
          },
        ],
      },
      regFrom: {
        useremail: "",
        verificationval: "",
        password: "",
        secondpassword: "",
      },
    };
  },
  methods: {
    changelang(ruleForm) {
      let { useremail, verificationval, password, secondpassword } = this.regFrom;
      let pasVal = {
        email: useremail,
        randomNun: verificationval,
        password: password + "&&" + secondpassword,
      };
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          unlock.resetPas(pasVal).then((res) => {
            this.$message.success("账号解锁" + res.msg);
            this.regFrom.useremail = "";
            this.regFrom.verificationval = "";
            this.regFrom.password = "";
            this.regFrom.secondpassword = "";
            this.sliderVal = false;
            this.$router.push("/");
          });
        } else {
          return false;
        }
      });
    },
    forgetMsg(data) {
      this.sliderVal = data;
      console.log(data);
    },
    sendmsg() {
      /* 发送验证码 */
      if (this.regFrom.useremail && !this.timer && this.sliderVal) {
        let sendval = {
          email: this.regFrom.useremail,
        };
        unlock.sendMail(sendval).then((res) => {
          this.$message.success(res.msg);
          this.getCode();
        });
      }
    },
    getCode() {
      /* 倒计时 */
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.msgtime = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.msgtime = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
  },
  components: {
    SliderLogin,
  },
};
</script>

<style lang="less" scoped>
.box-card {
  /* 卡片 */
  width: 32%;
  padding: 44px 76px 53px 80px;
  box-sizing: border-box;
  margin-right: 12%;
  position: relative;
}
.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 44px;
  p {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #424242;
    margin-bottom: 13px;
  }
}
.line {
  width: 58px;
  height: 2px;
  background: #131715;
}
.firstcontent {
  img {
    width: 14px;
    height: 20px;
  }
}
.determine {
  button {
    width: 460px;
    height: 60px;
    margin-top: 60px;
    background: linear-gradient(0deg, #333232, #131715);
    border-radius: 6px;
    outline-style: none;
    border: none;
    p {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 42px;
    }
  }
}
/* 验证码发送 */
.sendmsg {
  width: 116px;
  height: 50px;
  background: #3e3f3e;
  border-radius: 6px;
  position: absolute;
  right: 6px;
  top: 4px;
  z-index: 10;
  p {
    text-align: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 50px;
  }
}
/* 滑块样式 */
.slider {
  width: 460px;
  height: 58px;
  background: #e6e6e6;
  border-radius: 2px;
  margin-bottom: 19px;
}
</style>
